<template>
  <div class="entities" v-if="canView">
    <page-header
      title="Renouvellement/Transfert d'inscriptions aux activités"
      icon="fa fa-person-walking-dashed-line-arrow-right"
      :links="getLinks()"
    >
    </page-header>
    <b-row>
      <b-col>
        <b-form-group label-for="kindChoice" label="Type d'activité">
          <b-select id="kindChoice" v-model="selectedKind" :disabled="kindChoices.length <= 1">
            <b-select-option :value="elt" v-for="elt in kindChoices" :key="elt.id">
              {{ elt.name }}
            </b-select-option>
          </b-select>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label-for="actionChoice" label="Action à réaliser" :description="actionDescription">
          <b-select id="actionChoice" v-model="selectedAction">
            <b-select-option :value="elt" v-for="elt in actionChoices" :key="elt.id">
              {{ elt.name }}
            </b-select-option>
          </b-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-row>
          <b-col cols="4">
            <b-form-group label="Année" label-for="year">
              <b-form-select
                id="fromYear"
                v-model="selectedSchoolYear"
                :disabled="isLoading(postingName)"
              >
                <b-select-option :value="elt" v-for="elt in schoolYears" :key="elt.id">
                  {{ elt.name }}
                </b-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Inscrits à" label-for="activity">
              <b-form-select
                id="activity"
                v-model="selectedActivity"
                :disabled="isLoading(loadingName) || isLoading(postingName) || !selectedSchoolYear"
                @change="onActivityChanged()"
              >
                <b-select-option :value="elt" v-for="elt in schoolYearActivities" :key="elt.id">
                  {{ elt.name }}
                </b-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col>
        <b-row>
          <b-col cols="4">
            <b-form-group label="Année" label-for="year2">
              <b-form-select
                id="fromYear"
                v-model="selectedSchoolYear2"
                :disabled="isLoading(postingName)"
              >
                <b-select-option :value="elt" v-for="elt in schoolYears" :key="elt.id">
                  {{ elt.name }}
                </b-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group :label="selectedAction.name + ' à'" label-for="activity2" v-if="selectedAction">
              <b-form-select
                id="activity2"
                v-model="selectedActivity2"
                :disabled="isLoading(loadingName) || isLoading(postingName) || !selectedSchoolYear2 || !selectedActivity"
                @change="onActivity2Changed()"
              >
                <b-select-option :value="elt" v-for="elt in schoolYear2Activities" :key="elt.id">
                  {{ elt.name }}
                </b-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <div v-if="activity2">
          <b-row>
            <b-col>
              <b-form-group
                label-for="price"
              >
                <b-form-select
                  id="price"
                  v-model="selectedPrice2"
                  required
                  :disabled="activity2.getPrices().length <= 1 || isLoading(postingName)"
                >
                  <b-form-select-option
                    :value="0"
                    v-if="activity2.getPrices().length === 0"
                  >
                    Gratuit
                  </b-form-select-option>
                  <b-form-select-option
                    :value="price.index"
                    v-for="price in activity2.getPrices()"
                    :key="price.index"
                  >
                    {{ price.name }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col v-if="isYouthHome">
              <b-form-group
                label-for="analytic"
                description="Compte analytique utilisé pour les ventes des inscriptions aux séances"
              >
                <b-form-select
                  id="analytic"
                  v-model="selectedAnalyticAccountId2"
                  :disabled="!(activity2 && activity2.analyticAccounts.length > 1) || isLoading(postingName)"
                >
                  <b-form-select-option
                    :value="analyticAccount.id"
                    v-for="analyticAccount in activity2.analyticAccounts"
                    :key="analyticAccount.id"
                  >
                    {{ analyticAccount.name }}
                  </b-form-select-option>
                </b-form-select>
                <div class="analytic-error" v-if="analyticAccountError()">{{ analyticAccountError() }}</div>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="showYearWarning">
      <b-col>
        <div class="warning-transfer">
          Vous allez transférer les inscrits d'une année à une autre. Êtes vous sûr de continuer?
        </div>
      </b-col>
    </b-row>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div class="renew-filter-bar" v-if="!isLoading(loadingName)">
      <b-row>
        <b-col>
          <b-form-group label="Années de naissance" label-for="births" v-if="birthYears.length">
            <check-box-select
              inline
              :choices="birthYears"
              @changed="onBirthYearsChanged"
              id="births"
            ></check-box-select>
          </b-form-group>
          <b-form-group
            label="Niveaux scolaires"
            label-for="schoolLevels" v-if="schoolLevels.length"
          >
            <check-box-select
              inline
              :choices="schoolLevels"
              @changed="onSchoolLevelsChanged"
              id="schoolLevels"
            >
            </check-box-select>
          </b-form-group>
        </b-col>
        <b-col cols="2" v-if="isYouthHome">
          <b-form-group label="Filtres" label-for="onlyIfSeanceInscriptions">
            <b-form-checkbox
              id="onlyIfSeanceInscriptions"
              v-model="onlyIfSeanceInscriptions"
              @change="onOnlyIfChanged"
            >
              Seulement les inscrits à au moins une séance
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col cols="2" v-if="!isYouthHome">
          <b-form-group label="Filtres" label-for="showTrying">
            <b-form-checkbox id="showTrying" v-model="showTrying">à l'essai</b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col cols="2" v-if="!isYouthHome">
          <b-form-group label="-" label-for="showWaiting">
            <b-form-checkbox id="showWaiting" v-model="showWaiting">en attente</b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <b-row>
      <b-col>
        <div class="progress-bar-holder">
          <b-progress :max="slicesCount" height="10px" v-if="isLoading(postingName)">
            <b-progress-bar :value="currentSlice"></b-progress-bar>
          </b-progress>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="8">
        <div class="help-text" v-if="selectedActivity ">
          <span v-if="renew && selectedActivity2">
            Seulement les personnes non inscrites à {{ selectedActivity2.name }}
          </span>
          <span v-else>Tous les inscrits à {{ selectedActivity.name }}</span>
        </div>
        <x-table
          id="inscriptions"
          :items="items"
          :columns="columns"
          :show-counter="showCounter"
          :verboseName="'inscription à ' + (renew ? 'renouveler' : 'transférer')"
          :verboseNamePlural="'inscriptions à ' + (renew ? 'renouveler' : 'transférer')"
          @selectionChanged="onSelectionChanged($event)"
          :keep-selection-on-filter="true"
          v-if="!isLoading(loadingName)"
        >
        </x-table>
      </b-col>
      <b-col>
        <div class="help-text" v-if="selectedActivity">
          <span v-if="renew && selectedActivity2">
            Seulement les inscrits à {{ selectedActivity.name }} et à {{ selectedActivity2.name }}
          </span>
          <span v-else-if="selectedActivity2">Tous les inscrits à {{ selectedActivity2.name }}</span>
          <span v-else>&nbsp;</span>
        </div>
        <x-table
          id="inscriptions2"
          :items="items2"
          :columns="columns2"
          :show-counter="showCounter"
          :verboseName="'inscription ' + (renew ? 'renouvelée' : '')"
          :verboseNamePlural="'inscriptions ' + (renew ? 'renouvelées' : '')"
          v-if="!isLoading(loadingName)"
        >
        </x-table>
      </b-col>
    </b-row>
    <renew-activity-confirm-modal
      name="renew-activity-inscriptions"
      icon="fa fa-person-walking-dashed-line-arrow-right"
      :title="(renew ? 'Renouvellement' : 'Transfert') + ' des inscriptions'"
      :text="confirmRunText()"
      @confirmed="runAction($event)"
    >
    </renew-activity-confirm-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapMutations } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import LoadingGif from '@/components/Controls/LoadingGif'
import PageHeader from '@/components/Layout/PageHeader'
import CheckBoxSelect from '@/components/Controls/CheckBoxSelect'
import RenewActivityConfirmModal from '@/components/Tools/RenewActivityConfirmModal'
import XTable from '@/components/Controls/Table/Table'
import { makeActivity, makeActivityInscription } from '@/types/activities'
import { distinctString, existsIn, subGrouperAny } from '@/utils/arrays'
import { compareNumbers } from '@/utils/sorting'
import store from '@/store'
import { makeChoice } from '@/types/base'
import { makeEntity, makeIndividual } from '@/types/people'
import { makeSchoolLevel } from '@/types/schools'

export default {
  name: 'RenewActivityInscriptions',
  mixins: [BackendMixin],
  components: {
    RenewActivityConfirmModal,
    CheckBoxSelect,
    XTable,
    LoadingGif,
    PageHeader,
  },
  data() {
    return {
      loadingName: 'renew-activity-inscriptions',
      postingName: 'post-renew-activity-inscriptions',
      entities: [],
      columns: [],
      columns2: [],
      showCounter: true,
      selectedItems: [],
      activities: [],
      activityInscriptions: [],
      activityInscriptions2: [],
      selectedSchoolYear: null,
      selectedActivity: null,
      selectedSchoolYear2: null,
      selectedActivity2: null,
      activityInscriptionsMap: new Map(),
      activityInscriptions2Map: new Map(),
      selectedBirthYears: [],
      selectedPrice2: 0,
      selectedAnalyticAccountId2: 0,
      activity2: null,
      slicesCount: 0,
      currentSlice: 0,
      selectedAction: null,
      actionChoices: [],
      selectedKind: null,
      kindChoices: [],
      showWaiting: false,
      showTrying: false,
      schoolLevels: [],
      selectedSchoolLevels: [],
      schoolLevelById: new Map(),
      onlyIfSeanceInscriptions: false,
    }
  },
  computed: {
    isYouthHome() {
      return (
        this.selectedKind === null ||
        this.selectedKind.id === 1
      )
    },
    renew() {
      return (
        this.selectedAction === null ||
        this.selectedAction.id === 1
      )
    },
    showYearWarning() {
      if (this.selectedAction && (this.selectedAction.id === 2)) {
        if (this.selectedActivity && this.selectedActivity2) {
          return this.selectedActivity.schoolYear.id !== this.selectedActivity2.schoolYear.id
        }
      }
      return false
    },
    actionDescription() {
      if (this.selectedAction) {
        if (this.selectedAction.id === 1) {
          return 'Les membres seront inscrits aux 2 activités'
        }
        if (this.selectedAction.id === 2) {
          return 'Les membres seront désinscrits de la 1ère activité et inscrits à la seconde'
        }
      }
      return ''
    },
    youthHomeLabel() {
      return store.getters.youthHomeLabel
    },
    formInvalid() {
      return (
        (this.selectedItems.length === 0) ||
        (this.selectedActivity2 === null) ||
        (this.selectedActivity2.youth && !this.selectedAnalyticAccountId2)
      )
    },
    filteredActivities() {
      return this.activities.filter(
        activity => activity.youth === this.isYouthHome
      )
    },
    filteredInscriptions() {
      const birthYears = this.selectedBirthYears.map(elt => elt.id)
      let activityInscriptions = this.activityInscriptions
      if (birthYears.length) {
        activityInscriptions = activityInscriptions.filter(
          ins => existsIn([ins.individual.birthYear()], birthYears)
        )
      }
      const schoolLevels = this.selectedSchoolLevels.map(elt => elt.id)
      if (schoolLevels.length) {
        activityInscriptions = activityInscriptions.filter(
          ins => {
            let level = this.schoolLevelById.get(ins.id)
            if (level) {
              return existsIn([level.id], schoolLevels)
            }
            return false
          }
        )
      }
      if (!this.showWaiting) {
        activityInscriptions = activityInscriptions.filter(ins => !ins.waiting)
      }
      if (!this.showTrying) {
        activityInscriptions = activityInscriptions.filter(ins => !ins.trying)
      }
      return activityInscriptions
    },
    items() {
      let filteredInscriptions = this.filteredInscriptions
      if (this.renew) {
        filteredInscriptions = filteredInscriptions.filter(
          // enlève de la liste les personnes déjà réinscrites
          elt => !this.activityInscriptions2Map.has(elt.individual.id)
        )
      }
      return filteredInscriptions.map(elt => this.makeItem(elt))
    },
    items2() {
      let activityInscriptions2 = this.activityInscriptions2
      if (this.renew) {
        activityInscriptions2 = activityInscriptions2.filter(
          // enlève de la liste les personnes qui n'étaient pas inscrites
          elt => this.activityInscriptionsMap.has(elt.individual.id)
        )
      }
      return activityInscriptions2.map(elt => this.makeItem(elt))
    },
    canView() {
      return this.hasOnePerm(
        [
          'activities.view_coreactivityinscription',
          'youth.view_youthhomeactivityinscription'
        ]
      )
    },
    canChange() {
      if (this.selectedKind) {
        if (this.selectedKind.id === 1) {
          return this.hasPerm('youth.view_youthhomeactivityinscription')
        } else {
          return this.hasPerm('activities.view_coreactivityinscription')
        }
      }
      return false
    },
    schoolYears() {
      const schoolYearIds = []
      const schoolYears = []
      for (let activity of this.filteredActivities) {
        if (schoolYearIds.indexOf(activity.schoolYear.id) < 0) {
          schoolYearIds.push(activity.schoolYear.id)
          schoolYears.push(activity.schoolYear)
        }
      }
      return schoolYears
    },
    schoolYearActivities() {
      if (this.selectedSchoolYear) {
        return this.filteredActivities.filter(elt => elt.schoolYear.id === this.selectedSchoolYear.id)
      }
      return []
    },
    schoolYear2Activities() {
      if (this.selectedSchoolYear2) {
        return this.filteredActivities.filter(elt => elt.schoolYear.id === this.selectedSchoolYear2.id)
      }
      return []
    },
    birthYears() {
      return distinctString(
        this.activityInscriptions.map(
          elt => '' + (elt.individual.birthYear() || '')
        ).filter(elt => elt)
      ).map(
        elt => {
          return { id: +elt, name: '' + elt, }
        }
      ).sort(
        (elt1, elt2) => compareNumbers(elt1.id, elt2.id)
      )
    },
  },
  mounted() {
    this.onLoaded()
  },
  watch: {
    isYouthHome: function() {
      this.selectedActivity = null
      this.selectedActivity2 = null
    },
    schoolYears: function() {
      if (this.schoolYears.length) {
        this.selectedSchoolYear = this.schoolYears[this.schoolYears.length - 1]
        this.selectedSchoolYear2 = this.schoolYears[0]
      }
    },
    selectedKind: function() {
      this.showWaiting = false
      this.showTrying = false
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    showModal() {
      this.$bvModal.show('bv-confirm-modal:renew-activity-inscriptions')
    },
    confirmRunText() {
      if (this.selectedActivity2 && this.selectedItems.length) {
        let text = ''
        if (this.selectedItems.length === 1) {
          text += 'Inscrire ' + this.selectedItems[0].fullName
        } else {
          text += 'Inscrire les ' + this.selectedItems.length + ' personnes sélectionnées'
        }
        text += ' à ' + this.selectedActivity2.name + ' ' + this.selectedSchoolYear2.name + '. '
        if (!this.renew) {
          text += 'Les personnes seront aussi désinscrites de ' + this.selectedActivity.name + '. '
        }
        return text
      }
      return ''
    },
    async runAction(event) {
      const groups = subGrouperAny(this.selectedItems, 10)
      this.slicesCount = groups.length
      this.currentSlice = 0
      let url = '/tools/api/create-many-activity-inscriptions/' + this.selectedActivity2.id + '/'
      this.startLoading(this.postingName)
      for (const group of groups) {
        try {
          const data = {
            price_index: this.selectedPrice2,
            activity_analytic_account: this.selectedAnalyticAccountId2,
            inscriptions: group.values.map(
              elt => {
                return {
                  individual: elt.individualId,
                  entity: elt.entityId,
                }
              }
            ),
            force_waiting: event.forceWaiting,
          }
          if (this.selectedAction.id === 2) {
            // Transférer : On annule l'inscription à l'activité d'origine
            data['cancel_activity'] = this.selectedActivity.id
          }
          let backendApi = new BackendApi('post', url)
          let resp = await backendApi.callApi(data)
          this.activityInscriptions2 = this.activityInscriptions2.concat(
            resp.data.map(elt => makeActivityInscription(elt))
          )
          if (this.selectedAction.id === 2) {
            await this.loadInscriptions()
            await this.loadInscriptions2()
          } else {
            this.refreshInscriptions2()
          }
          this.currentSlice += 1
        } catch (err) {
          await this.addError(this.getErrorText(err))
          break
        }
      }
      this.endLoading(this.postingName)
    },
    getLinks() {
      const links = []
      if (this.canChange) {
        links.push(
          {
            id: 1,
            label: this.renew ? 'Réinscrire' : 'Transférer',
            callback: this.showModal,
            icon: this.renew ? 'fa fa-arrow-right' : 'fa fa-arrow-right-from-bracket',
            cssClass: (
              (this.isLoading(this.loadingName) || this.isLoading(this.postingName) || this.formInvalid)
                ? 'btn-primary disabled' : 'btn-primary'
            ),
          }
        )
      }
      return links
    },
    onSelectionChanged(event) {
      this.selectedItems = event.items
    },
    getColumns(list1) {
      let columns = [
        {
          name: 'lastName',
          label: 'Nom',
          maxWidth: '100px',
          isLink: item => {
            return (item.entityId)
          },
          onClick: item => {
            this.showEntitySidebar(makeEntity({ id: item.entityId, }))
          },
        },
        {
          name: 'firstName',
          label: 'Prénom',
          maxWidth: '100px',
          isLink: item => {
            return (item.individualId)
          },
          onClick: item => {
            this.showIndividualSidebar(makeIndividual({ id: item.individualId, }))
          },
        },
        {
          name: 'status',
          label: '',
          maxWidth: '100px',
          contentCallback: (col, item) => {
            let status = ''
            if (item.waiting) {
              status = 'en attente'
            } else if (item.trying) {
              status = 'à l\'essai'
            }
            if (status) {
              return '<span class="badge badge-warning">' + status + '</span>'
            }
            return ''
          },
        }
      ]
      if (list1) {
        columns = [
          { selector: true, 'name': 'selector', maxWidth: '20px', }
        ].concat(columns).concat(
          [
            {
              name: 'birthYear',
              label: 'Né(e) en',
              maxWidth: '100px',
              number: true,
            }
          ]
        )
        if (this.schoolLevels.length) {
          columns = columns.concat(
            [
              {
                name: 'schoolLevel',
                label: 'Classe',
                maxWidth: '100px',
              }
            ]
          )
        }
      }
      return columns
    },
    makeItem(elt) {
      let level = this.schoolLevelById.get(elt.id) || null
      return {
        id: elt.id,
        lastName: elt.individual.lastName,
        firstName: elt.individual.firstName,
        fullName: elt.individual.firstAndLastName(),
        birthYear: elt.individual.birthYear() || '',
        schoolLevel: level ? level.name : '',
        individualId: elt.individual.id,
        entityId: elt.entity.id,
        waiting: elt.waiting,
        trying: elt.trying,
        status: (elt.waiting || elt.trying) ? 1 : '',
      }
    },
    onLoaded() {
      this.actionChoices = [
        makeChoice({ id: 1, name: 'Réinscrire', }),
        makeChoice({ id: 2, name: 'Transférer', })
      ]
      this.selectedAction = this.actionChoices[0]
      this.kindChoices = []
      if (this.hasPerm('youth.view_youthhomeactivityinscription')) {
        this.kindChoices.push(makeChoice({ id: 1, name: this.youthHomeLabel, }))
      }
      if (this.hasPerm('activities.view_coreactivityinscription')) {
        this.kindChoices.push(makeChoice({ id: 2, name: 'Activités', }))
      }
      if (this.kindChoices.length) {
        this.selectedKind = this.kindChoices[0]
      }
      this.loadActivities()
      this.columns = this.getColumns(true)
      this.columns2 = this.getColumns(false)
    },
    async loadActivities() {
      this.startLoading(this.loadingName)
      let url = '/tools/api/all-activities/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.activities = resp.data.map(elt => makeActivity(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    async loadActivity2() {
      if (this.selectedActivity2) {
        this.startLoading(this.loadingName)
        let url = '/api/activities/activities/'
        if (this.selectedActivity2.youth) {
          url = '/api/youth/activities/'
        }
        const backendApi = new BackendApi('get', url + this.selectedActivity2.id + '/')
        try {
          const resp = await backendApi.callApi()
          this.activity2 = makeActivity(resp.data)
          if (this.activity2.analyticAccounts.length === 1) {
            this.selectedAnalyticAccountId2 = this.activity2.analyticAccounts[0].id
          }
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.loadingName)
      } else {
        this.activity2 = null
      }
    },
    async loadInscriptions() {
      const activityInscriptionsMap = new Map()
      if (this.selectedActivity) {
        this.activityInscriptions = await this.getInscriptions(
          this.selectedActivity, true, this.onlyIfSeanceInscriptions && this.isYouthHome
        )
        for (const ins of this.activityInscriptions) {
          activityInscriptionsMap.set(ins.individual.id, ins)
        }
      } else {
        this.activityInscriptions = []
      }
      this.activityInscriptionsMap = activityInscriptionsMap
      this.columns = this.getColumns(true)
    },
    async loadInscriptions2() {
      if (this.selectedActivity2) {
        this.activityInscriptions2 = await this.getInscriptions(this.selectedActivity2)
      } else {
        this.activityInscriptions2 = []
      }
      this.refreshInscriptions2()
    },
    refreshInscriptions2() {
      const activityInscriptions2Map = new Map()
      for (const ins of this.activityInscriptions2) {
        activityInscriptions2Map.set(ins.individual.id, ins)
      }
      this.activityInscriptions2Map = activityInscriptions2Map
    },
    async getInscriptions(activity, loadSchoolLevels = false, onlyIfSeanceInscriptions = false) {
      let inscriptions = []
      if (activity) {
        this.startLoading(this.loadingName)
        try {
          let url = '/tools/api/create-many-activity-inscriptions/' + activity.id + '/'
          if (onlyIfSeanceInscriptions) {
            url += '?only_if_seance_inscriptions=1'
          }
          let backendApi = new BackendApi('get', url)
          let resp = await backendApi.callApi()
          inscriptions = []
          const schoolLevelsMap = new Map()
          const schoolLevelById = new Map()
          for (const insData of resp.data) {
            const ins = makeActivityInscription(insData)
            inscriptions.push(ins)
            if (loadSchoolLevels && insData['school_level']) {
              const schoolLevel = makeSchoolLevel(insData['school_level'])
              if (!schoolLevelsMap.has(schoolLevel.id)) {
                schoolLevelsMap.set(schoolLevel.id, schoolLevel)
              }
              schoolLevelById.set(ins.id, schoolLevel)
            }
          }
          if (loadSchoolLevels) {
            this.schoolLevels = Array.from(schoolLevelsMap.values()).sort(
              (elt1, elt2) => compareNumbers(elt1.age, elt2.age)
            )
            this.schoolLevelById = schoolLevelById
          }
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.loadingName)
      }
      return inscriptions
    },
    onActivityChanged() {
      this.loadInscriptions()
    },
    onOnlyIfChanged() {
      if (this.isYouthHome) {
        this.loadInscriptions()
      }
    },
    async onActivity2Changed() {
      await this.loadActivity2()
      await this.loadInscriptions2()
    },
    onBirthYearsChanged(event) {
      this.selectedBirthYears = event.choices
    },
    onSchoolLevelsChanged(event) {
      this.selectedSchoolLevels = event.choices
    },
    analyticAccountError() {
      if (!this.selectionError) {
        if (this.isYouthHome && this.activity2) {
          if (this.activity2.analyticAccounts.length === 0) {
            return 'Erreur de configuration: Aucun compte analytique n\'est associé à ' + this.activity2.name
          }
          if (!this.selectedAnalyticAccountId2) {
            return 'Vous devez saisir un compte analytique'
          }
        }
      }
      return ''
    },
  },
}
</script>

<style lang="less">
.keep-tags {
  padding: 10px;
  margin-bottom: 5px;
  border-bottom: solid 1px #ccc;
  background: #fdffb6;
  font-weight: bold;
}
.analytic-error {
  color: #b42837;
  font-size: 13px;
  font-weight: bold;
}
.progress-bar-holder {
  margin-bottom: 4px;
  margin-top: -14px;
}
.progress .progress-bar{
  background: #0d9819;
}
.warning-transfer {
  background: #f0ad4e;
  font-weight: bold;
  padding: 5px 10px;
}
.renew-filter-bar {
  background: #f0f0f0;
  padding: 4px;
  margin-bottom: 2px;
}
</style>
