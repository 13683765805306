<template>
  <span class="renew-activity-confirm-modal">
    <b-modal
      :id="'bv-confirm-modal:' + name"
      cancel-title="Annuler"
      @ok="onConfirm"
      @cancel="onCancel"
      ok-variant="primary"
      ok-title="Inscrire"
    >
      <template v-slot:modal-title>
        <i :class="icon" v-if="icon"></i>
        {{ title }}
      </template>
      <b-form ref="form">
        {{ text }}
        <div v-if="text2" class="confirm-text2">
          {{ text2 }}
        </div>
        <div class="confirm-options">
          <b-form-checkbox id="forceWaiting" v-model="forceWaiting">
            <b>Inscrire en attente</b>
          </b-form-checkbox>
          <div class="confirm-text2 confirm-text-warning" v-if="forceWaiting">
            <b>Les renouvellements seront mis en attente.</b><br /><br />
            Les ventes associées ne seront disponibles que lors de la confirmation de chaque
            inscription.
          </div>
        </div>
      </b-form>
    </b-modal>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>

export default {
  name: 'RenewActivityConfirmModal',
  components: {
  },
  props: {
    name: String,
    text: String,
    icon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      defaultValue: 'Confirmation',
    },
    object: {
      type: Object,
      defaultValue: null,
    },
    text2: {
      type: String,
      defaultValue: '',
    },
  },
  data() {
    return {
      forceWaiting: false,
    }
  },
  watch: {
  },
  computed: {
  },
  methods: {
    onConfirm() {
      this.$emit('confirmed', { name: this.name, object: this.object, forceWaiting: this.forceWaiting, })
    },
    onCancel() {
      this.$emit('cancelled', { name: this.name, object: this.object, })
    },
  },
}
</script>
<style lang="less" scoped>
.confirm-options {
  margin-top: 10px;
}
.confirm-text2 {
  margin-top: 10px;
}
.confirm-text-warning {
  padding: 5px;
  background: #e2c88a;
}
</style>
